import * as React from "react";
import { MailFilled } from '@ant-design/icons';
import Table from "../shared/components/AccessibleTable/Table";
import { observer } from "mobx-react";
import AppStore from "../shared/stores/AppStore";
import EmailSubscriptionStore from "../shared/stores/EmailSubscriptionStore";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import { Button } from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { BarLoader } from 'react-spinners';
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import { SubscriptionListState } from "./SubscriptionListState";
import UserStore from "../shared/stores/UserStore";
import AppInsightsService from "../shared/services/AppInsightsService";
import Grid from "@material-ui/core/Grid";

@observer
export class SubscriptionList extends React.Component<RouteComponentProps<{}>, SubscriptionListState> {
    constructor(props) {
        super(props);
        this.state = new SubscriptionListState();
    }

    componentDidMount() {
        AppStore.setPageTitle("Subscriptions");
        this.state.updateUser(UserStore.username.replace(/@.+/, ''));
        if(!EmailSubscriptionStore.isLoaded) EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true);
    }

    render() {
        if(!EmailSubscriptionStore.isLoaded || EmailSubscriptionStore.isLoading) {
            return <div style={{paddingTop: "100px"}}><LoadingIndicator text="Loading..."/></div>
        }

        const subscriptionList = (EmailSubscriptionStore.http && EmailSubscriptionStore.http.success && EmailSubscriptionStore.data != null) ?
                                EmailSubscriptionStore.data.map((i) => { return {
                                    SubscriptionId: i,
                                    SubscriptionType: i.indexOf('|') === -1 ? "FunctionalGroup" : "Audience",
                                    FgName: i.split("|")[0],
                                    AudName: i.split("|")[1] || ""
                                }}).sort((a, b) => a.SubscriptionId.localeCompare(b.SubscriptionId)) : [];

        return (
            <div>
                <Table
                    title="" name="Subscriptions table"
                    columns={[
                        { label: 'Subscription Id', field: 'SubscriptionId', type: 'string', filterOptions: { filterable: true, filterType: 'search' }, renderDataFn: this.renderTargetLink },
                        { label: 'Subscription Type', field: 'SubscriptionType', type: 'string', filterOptions: { filterable: true, filterType: 'select' } },
                        { label: 'Functional Group', field: 'FgName', type: 'string', filterOptions: { filterable: true, filterType: 'search' } },
                        { label: 'Audience Name', field: 'AudName', type: 'string', filterOptions: { filterable: true, filterType: 'search' } },
                    ]}
                    data={subscriptionList}
                    paginationOptions={{ paginateTable: true }}
                    searchOptions={{ enableSearch: true }}
                    selectionOptions={{ enableSelection: true, selectionField: 'SubscriptionId', onSelectionChanged: this.state.setCheckedSubscriptions }}
                />
                {EmailSubscriptionStore.isCreateInProgress ? <div><BarLoader /></div> :
                    <div style={{ float: "right" }}>
                        <div><Button className="ts-btn" onClick={this.unsubscribeIds} disabled={this.state.checkedSubscriptions.length === 0}>Unsubscribe <MailFilled type="mail" className="listIcon" /></Button></div>
                    </div>
                }
            </div>
        );
    }

    private renderTargetLink = (id, subscription) => {
        return <Link to={subscription.AudName? `/audience/details/functionalgroup/${subscription.FgName}/audience/${subscription.AudName}` : `/audiences/functionalgroup/${subscription.FgName}`}>{subscription.SubscriptionId}</Link>;
    }

    private unsubscribeIds = async () => {
        if (this.state.checkedSubscriptions.length === 0) AlertStore.add(AlertType.Error, "No subscriptions selected");
        else {
            await Promise.all(this.state.checkedSubscriptions.map(async sub => {
                await EmailSubscriptionStore.UnsubscribeUser(sub.SubscriptionId, sub.FgName, sub.AudName);
            }));
            this.state.setCheckedSubscriptions([]);
            EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true);
        }
    }
}

export default AppInsightsService.trackComponent(SubscriptionList);