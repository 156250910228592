import * as React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import "./AudienceList.css";
import AppStore from "../shared/stores/AppStore";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import AudienceStore from "../shared/stores/AudienceStore";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import { Button, Input, Row, Col, InputGroup, InputGroupAddon } from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import Table from '../shared/components/AccessibleTable/Table';
import { BarLoader } from 'react-spinners';
import moment from "moment";
import { AudienceListState } from "./AudienceListState";
import { Attributes, AudienceCriteriaType } from "ts-infra-common";
import { getRevisionDateTimeString } from "../shared/helpers/dateTimeHelpers";
import AlertStore from "../shared/stores/AlertStore";
import EmailSubscriptionStore from "../shared/stores/EmailSubscriptionStore";
import UserStore from "../shared/stores/UserStore";

import AppInsightsService from "../shared/services/AppInsightsService";
import AppInsightsPageTracker from "../shared/services/AppInsightsPageTracker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "../shared/components/AccessibleSelect/Select";
import { MailFilled } from "@ant-design/icons";

function getAttrDisplay(attributes: Attributes) {
    const disabled = attributes & Attributes.Disabled;
    const deleted = attributes & Attributes.Deleted;
    return `${disabled ? '(Disabled)' : ''}${deleted ? '(Deleted)' : ''}`;
}

@observer
export class AudienceTable extends React.Component<RouteComponentProps<{ functionalGroupId?: string, alias?: string }>, AudienceListState> {
    static hasMounted: boolean = false;
    pageLoadTracker: AppInsightsPageTracker;
    constructor(props) {
        super(props);
        this.state = new AudienceListState();
    }

    componentDidMount() {
        AudienceTable.hasMounted = true;
        AppInsightsService.trackPageView("AudienceList");
        this.pageLoadTracker = AppInsightsService.startTrackPage();
        AppStore.setPageTitle("Audiences");
        this.state.updateCurrentUser(UserStore.username.replace(/@.+/, ''));
        if(!FunctionalGroupStore.isLoaded) {
            FunctionalGroupStore.fetchAllFunctionalGroups(true).then(() => {
                if (this.props.match.params.functionalGroupId) this.onChangeFunctionalGroup(this.props.match.params.functionalGroupId);
                else if (localStorage.functionalGroup) this.onChangeFunctionalGroup(localStorage.functionalGroup);
                else if (!this.props.match.params.alias) this.onChangeFunctionalGroup(FunctionalGroupStore.data[0].id);
            });
        } else {
            if (this.props.match.params.functionalGroupId) this.onChangeFunctionalGroup(this.props.match.params.functionalGroupId);
            else if (localStorage.functionalGroup) this.onChangeFunctionalGroup(localStorage.functionalGroup);
            else if (!this.props.match.params.alias) this.onChangeFunctionalGroup(FunctionalGroupStore.data[0].id);
        }

        if (this.props.match.params.alias) {
            this.onChangeUserName(this.props.match.params.alias);
            this.handleOnClick(null);
        }

        if(!EmailSubscriptionStore.isLoaded) EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true);
    }

    render() {
        if (!FunctionalGroupStore.isLoaded || FunctionalGroupStore.isLoading) {
            return <div style={{paddingTop: "100px"}}><LoadingIndicator text="Loading..."/></div>
        }

        if (this.pageLoadTracker) {
            this.pageLoadTracker.stop();
        }

        const functionalGroups = (FunctionalGroupStore.http && FunctionalGroupStore.http.success && FunctionalGroupStore.data != null) ?
            FunctionalGroupStore.data.map((i) => ({
                        label: i.Name,
                        value: i.id
                    })).sort((a,b) => a.label.localeCompare(b.label))
            : [];
            const audiences = (!AudienceStore.isLoading && AudienceStore.isLoaded) ?
                AudienceStore.data.map((i) => ({
                    Name: `${getAttrDisplay(i.Attributes)}${i.ShortName}`,
                    Owner: i.Owner,
                    Admins: i.Admins ? i.Admins.filter((value, index, arr) => { return arr.indexOf(value) === index }).join(", ") : "",
                    LastChangedTime: getRevisionDateTimeString(i.TimeStamp),
                    FuncGroupId: i.FunctionalGroup,
                    Revision: i.Revision,
                    TimeStamp: i.TimeStamp,
                    SubscriptionId: `${i.FunctionalGroup ? i.FunctionalGroup : ""}|${i.ShortName ? i.ShortName : ""}`,
                    NoAttrName: i.ShortName,
                    Attributes: i.Attributes
                })).sort((a, b) => new Date(b.LastChangedTime).getTime() - new Date(a.LastChangedTime).getTime())
            : [];

        return (
            <div>
                <div className="audience-list-div">
                    <Row>
                        <Col>
                            <label id="primary-context-fg-label">Functional Group:</label>
                            <Select id="primary-context-fg" labelId="primary-context-fg-label" width="100%"
                                options={functionalGroups} onChange={this.onChangeFunctionalGroup} value={this.state.selectedFunctionalGroupId || null}
                                />
                        </Col>
                        <Col xs={1} className="text-center">
                            <label>or</label>
                        </Col>
                        <Col>
                        <label htmlFor="userName">Get all audiences for user:</label>
                        <InputGroup>
                            <Input placeholder={"Enter username..."} onKeyPress={this.handleKeyPress} name="userName"
                                id="userName"
                                onChange={this.onChangeUserName} value={this.state.selectedUserName || ''} aria-label="Get all audiences for user:" />
                            <InputGroupAddon addonType="append"><Button className="user-button" aria-label="Press to submit user" title="submit user" disabled={this.state.selectedUserName.length === 0} onClick={this.handleOnClick}>&#8594;</Button></InputGroupAddon>
                        </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <br></br>
                            <Button className="new-audience-button" onClick={()=>window.location.href=`/audience/create${this.state.selectedFunctionalGroupId ? `?fg=${this.state.selectedFunctionalGroupId}` : ''}`} >Create New Audience</Button>
                        </Col>
                    </Row>
                </div>
                {AudienceStore.isLoading || EmailSubscriptionStore.isLoading
                    ? <div style={{paddingTop: "100px"}}><BarLoader/></div>
                    : <div className="audience-table-div">
                        <Table title={<h3 className="audiences-table-label">Audiences</h3>} name="Audiences table"
                            columns={[
                                { label: 'Name', field: 'Name', type: 'string', sortOptions: { sortable: true }, filterOptions: { filterable: true, filterType: 'search' }, renderDataFn: this.renderAudienceLink },
                                { label: 'Owner', field: 'Owner', type: 'string', filterOptions: { filterable: true, filterType: 'search' } },
                                { label: 'Admins', field: 'Admins', type: 'string', filterOptions: { filterable: true, filterType: 'search' } },
                                { label: 'Last Changed (DD/MM/YYYY)', field: 'LastChangedTime', type: 'datetime', sortOptions: { sortable: true }, filterOptions: { filterable: true, filterType: 'search' }, renderDataFn: dateTime => dateTime + ' (' + moment(dateTime).fromNow() + ')' },
                            ]}
                            data={audiences}
                            paginationOptions={{ paginateTable: true, rowsPerPageChoices: [10, 25, 50] }}
                            searchOptions={{ enableSearch: true, filterRowFn: this.filterRows, renderSearchInputFn: this.renderSearchInput }}
                        />
                        <div>
                            <p><i>Click below to subscribe/unsubscribe to email notifications to any changes made to the audiences in the selected Functional Group.
                            <br/>You can also subscribe/unsubscribe to individual audiences in their details page.
                            <br/>You can manage your subscriptions <Link to={`/subscriptions`}>here</Link></i></p>
                            {
                                EmailSubscriptionStore.data != null && EmailSubscriptionStore.data.indexOf(this.state.selectedFunctionalGroupId?.toLowerCase()) > -1 ?
                                <Button className="ts-btn sublst-btn" onClick={this.UnsubscribeToFG} disabled={EmailSubscriptionStore.isCreateInProgress}>{this.state.unsubscribeText}<MailFilled type="mail" className="listIcon" /></Button> :
                                <Button className="ts-btn sublst-btn" onClick={this.SubscribeToFG} disabled={EmailSubscriptionStore.isCreateInProgress}>{this.state.subscribeText}<MailFilled type="mail" className="listIcon" /></Button>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }

    private renderAudienceLink = (name: string, audience: any) => {
        return <Link to={`/audience/details/functionalgroup/${audience.FuncGroupId}/audience/${audience.NoAttrName}`}>
            {name} {EmailSubscriptionStore.data != null && EmailSubscriptionStore.data.indexOf(audience.SubscriptionId) > -1 ? '   \u2709' : ''}
        </Link>;
    }

    private renderSearchInput = () => {
        return (
            <div className="searchDiv">
                <FormControlLabel control={<input className="audListCheckBox" type="checkbox" value="Show disabled audiences" checked={this.state.showDisabled} onChange={this.onChangeShowDisabled} disabled={this.checkboxIsDisabled()} />} label="Show disabled audiences" />
                <FormControlLabel control={<input className="audListCheckBox" type="checkbox" value="Show deleted audiences" checked={this.state.showDeleted} onChange={this.onChangeShowDeleted} disabled={this.checkboxIsDisabled()} />} label="Show deleted audiences" />
            </div>
        );
    }

    private filterRows = (audience: any, search: string) => {
        const attr = Attributes.None | (!this.state.showDisabled ? Attributes.Disabled : 0) | (!this.state.showDeleted ? Attributes.Deleted : 0);
        return (audience.Attributes & attr) === 0;
    }

    @action
    private SubscribeToFG = async () => {
        this.state.updateSubscribeText('Subscribing...');
        await EmailSubscriptionStore.SubscribeUser(this.state.selectedFunctionalGroupId, this.state.selectedFunctionalGroupId, null)
        .then(() => {
            this.state.updateSubscribeText('Subscribe   ');
            EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true);
        });
    }

    @action
    private UnsubscribeToFG = async () => {
        this.state.updateUnsubscribeText('Unsubscribing...');
        await EmailSubscriptionStore.UnsubscribeUser(this.state.selectedFunctionalGroupId, this.state.selectedFunctionalGroupId, null)
        .then(() => {
            this.state.updateUnsubscribeText('Unsubscribe   ');
            EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true);
        });
    }

    private checkboxIsDisabled = () => {
        if (this.state.selectedFunctionalGroupId.localeCompare('') === 0 && this.state.selectedUserName.localeCompare('') === 0) {
            return true;
        } else {
            return false;
        }
    }

    private handleKeyPress = (event) => {
        if (this.state.selectedUserName.localeCompare('') !== 0) {
            if (event.key === 'Enter' || onclick) {
                AlertStore.clearAll();
                this.props.history.push(`/audiences/user/${this.state.selectedUserName}`);
                this.getUserAudiences();
            }
        }
    }

    private handleOnClick = (event) => {
        AlertStore.clearAll();
        if (event) this.props.history.push(`/audiences/user/${this.state.selectedUserName}`);
        this.getUserAudiences();
    }

    @action
    private onChangeFunctionalGroup = (value: string) => {
        this.state.updateUser('');
        this.state.update(value);
        this.getFunctionalGroupAudiences();
        if (value) this.props.history.push(`/audiences/functionalgroup/${this.state.selectedFunctionalGroupId}`);
        localStorage.functionalGroup = this.state.selectedFunctionalGroupId;
    }

    private onChangeShowDisabled = () => {
        this.state.updateShowDisabled();
    }

    private onChangeShowDeleted = () => {
        this.state.updateShowDeleted();
    }

    private onChangeUserName = (value: any) => {
        const alias = (value.target && value.target.value) || '';
        if (alias != null) {
            this.state.update('');
            this.state.updateUser(alias);
        }
    }

    private getFunctionalGroupAudiences = () => {
        AudienceStore.fetchFuncGroupAudience(this.state.selectedFunctionalGroupId, true);
    }

    private getUserAudiences = () => {
        AudienceStore.fetchUserAudiences(this.state.selectedUserName, true);
    }
}

export default AppInsightsService.trackComponent(AudienceTable);