import * as React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { observer } from "mobx-react";
import { action } from 'mobx';
import { AvField, AvInput, AvGroup } from 'availity-reactstrap-validation';
import AttributeFormTransformProps from "../AttributeFormTransformProps";
import AppInsightsService from '../../../services/AppInsightsService';
import MultiInput from "../../AccessibleSelect/MultiInput";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


interface OtherTransformsState {
    ignore: string[];
    allow: string[]
    trim: boolean;
    lastToken: boolean;
    ignoreEmptyTokens: boolean;
    regexExamplePanel: boolean;
    substringExamplePanel: boolean;
    tokenizeExamplePanel: boolean;
}

@observer
export class OtherTransforms extends React.Component<AttributeFormTransformProps, OtherTransformsState> {
    constructor(props) {
        super(props);

        this.state = {ignore: this.props.data.Transform.Ignore || [], allow: [], trim: false, lastToken: false, ignoreEmptyTokens: false, regexExamplePanel: false, substringExamplePanel: false, tokenizeExamplePanel: false}
    }

    onChange = (e) => {
        const target = ["Transform", e.target.name]

        if(e.target) {
            if(e.target.name === "Trim") {
                this.setState({trim: !this.state.trim}, () => {this.props.onChange(target, this.state.trim)})
            }

            else if(e.target.name === "LastToken") {
                this.setState({lastToken: !this.state.lastToken}, () => {this.props.onChange(target, this.state.lastToken)})
            }

            else if(e.target.name === "IgnoreEmptyTokens") {
                this.setState({ignoreEmptyTokens: !this.state.ignoreEmptyTokens}, () => {this.props.onChange(target, this.state.ignoreEmptyTokens)})
            }

            else {
                if(e.target.type === 'number') {
                    const actualNumber = parseFloat(e.target.value)
                    this.props.onChange(target, actualNumber)
                }
                    else {
                    this.props.onChange(target, e.target.value)
                }
            }
        }
    }

    toggleRegexExamplePanel = () => {
        this.setState({regexExamplePanel: !this.state.regexExamplePanel})
    }


    renderRegexExamplePanel() {
        return (

        <div><ExpansionPanel expanded={this.state.regexExamplePanel} onChange={this.toggleRegexExamplePanel}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="regex-example-panel-content"
                id="regex-example-panel-header"
            >
                View Regex Example
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div style={{textIndent: '10px', fontSize: '90%'}}>
                    <div>
                        <p style={{fontWeight: 'bold'}}>
                            Example #1
                        </p>
                    </div>
                    <div style={{display: 'block'}}>
                        <div style={{textDecoration: 'underline'}}>Input</div>
                        <div>DC:409E5254,DC:409E624C,DC:409E84DD,DR:6D25B,DR:6D25C,DR:6D25D</div>
                    </div>
                    <p/>
                    <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>Regex: DR:[^,]*</div>
                        </div>
                    <p/>
                    <div style={{display: 'block'}}>
                        <div style={{textDecoration: 'underline'}}>Output</div>
                        <div>DR:6D25B,DR:6D25C,DR:6D25D</div>
                    </div>

                    <p/>
                    <div>
                        <p style={{fontWeight: 'bold'}}>
                            Example #2
                        </p>
                    </div>
                    <div style={{display: 'block'}}>
                        <div style={{textDecoration: 'underline'}}>Input</div>
                        <div>DC:409E5254,DC:409E624C,DC:409E84DD,DR:6D25B,DR:6D25C,DR:6D25D</div>
                    </div>
                    <p/>
                    <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>Regex: DR:[^,]*</div>
                            <div>RegexDelimiter: ;</div>
                        </div>
                    <p/>
                    <div style={{display: 'block'}}>
                        <div style={{textDecoration: 'underline'}}>Output</div>
                        <div>DR:6D25B;DR:6D25C;DR:6D25D</div>
                    </div>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel></div>
        )
    }

    toggleTokenizeExamplePanel = () => {
        this.setState({tokenizeExamplePanel: !this.state.tokenizeExamplePanel})
    }

    renderTokenizeExamplePanel = () => {
        return (

            <div><ExpansionPanel expanded={this.state.tokenizeExamplePanel} onChange={this.toggleTokenizeExamplePanel}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="tokenize-example-panel-content"
                    id="tokenize-example-panel-header"
                >
                    View Tokenize Example
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{textIndent: '10px', fontSize: '90%'}}>
                        <div>
                            <p style={{fontWeight: 'bold'}}>
                                Example #1
                            </p>
                        </div>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Input</div>
                            <div>Foo.Bar</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>Delimiter: "."</div>
                            <div>Token: 2</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Output</div>
                            <div>Bar</div>
                        </div>

                        <p/>
                        <div>
                            <p style={{fontWeight: 'bold'}}>
                                Example #2
                            </p>
                        </div>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Input</div>
                            <div>Foo..Bar</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>Delimiter: "."</div>
                            <div>Token: 2</div>
                            <div>IgnoreEmptyTokens: true</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Output</div>
                            <div>Bar</div>
                        </div>

                        <p/>
                        <div>
                            <p style={{fontWeight: 'bold'}}>
                                Example #3
                            </p>
                        </div>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Input</div>
                            <div>Foo.Bar.Foobar</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>Delimiter: "."</div>
                            <div>Token: 2</div>
                            <div>LastToken: true</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Output</div>
                            <div>Bar.Foobar</div>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel></div>
            )
    }

    toggleSubstringExamplePanel = () => {
        this.setState({substringExamplePanel: !this.state.substringExamplePanel})
    }

    renderSubstringExamplePanel = () => {
        return (

            <div><ExpansionPanel expanded={this.state.substringExamplePanel} onChange={this.toggleSubstringExamplePanel}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="substring-example-panel-content"
                    id="substring-example-panel-header"
                >
                    View Substring Example
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{textIndent: '10px', fontSize: '90%'}}>
                        <div>
                            <p style={{fontWeight: 'bold'}}>
                                Example #1
                            </p>
                        </div>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Input</div>
                            <div>Foo.Bar</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>SubStart: -3</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Output</div>
                            <div>Bar</div>
                        </div>

                        <p/>
                        <div>
                            <p style={{fontWeight: 'bold'}}>
                                Example #2
                            </p>
                        </div>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Input</div>
                            <div>Foo.Bar</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>SubLength: 3</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Output</div>
                            <div>Foo</div>
                        </div>

                        <p/>
                        <div>
                            <p style={{fontWeight: 'bold'}}>
                                Example #3
                            </p>
                        </div>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Input</div>
                            <div>Foo.Bar</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Transform</div>
                            <div>SubStart: 1</div>
                            <div>SubLength: 2</div>
                        </div>
                        <p/>
                        <div style={{display: 'block'}}>
                            <div style={{textDecoration: 'underline'}}>Output</div>
                            <div>oo</div>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel></div>
            )
    }

    validateRegex = (value, ctx, input, cb) => {
        try {
            const regex = new RegExp(value)
        } catch(err) {
            cb('Invalid regex')
            return;
        }

        cb(true)
    }

    updateAllowIgnore = (transform: string, arr: string[]) => {
        const target = ["Transform", transform]
        this.props.onChange(target, arr)
    }

    @action
    private setAllowValues = (value: string, op: 'add' | 'remove') => {
        const index = this.state.allow.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.setState({allow: [...this.state.allow, value]}, () => {this.updateAllowIgnore("Allow", this.state.allow)})
        }
        else if (op === 'remove' && index >= 0) {
            const newAllow = this.state.allow.slice();
            newAllow.splice(index, 1);
            this.setState({allow: newAllow}, () => {this.updateAllowIgnore("Allow", this.state.allow)})
        }
    }

    @action
    private setIgnoreValues = (value: string, op: 'add' | 'remove') => {
        const index = this.state.ignore.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.setState({ignore: [...this.state.ignore, value]}, () => {this.updateAllowIgnore("Ignore", this.state.ignore)})
        }
        else if (op === 'remove' && index >= 0) {
            const newIgnore = this.state.ignore.slice();
            newIgnore.splice(index, 1);
            this.setState({ignore: newIgnore}, () => {this.updateAllowIgnore("Ignore", this.state.ignore)})
        }
    }

    render() {
        const required = this.props.data.Transform.Delimiters ? true : false
        let validate
        if(required) {
            validate = {
                required: {
                    value: required,
                    errorMessage:'If Delimiters is being provided then Token must be provided'
                }
            }
        }

        return (
            <div>
                <Col>
                    <div style={{ marginBottom: "25px", marginTop: "10px" }}>
                        <Label check>
                            <AvInput type="checkbox" name="Trim" aria-labelledby="Trim" value={this.props.data.Transform.Trim} onChange={this.onChange} disabled={this.props.disabled} /> Trim whitespace
                        </Label>
                    </div>

                    <div style={{fontWeight: 'bold'}}>Regex</div>
                    <div style={{ border: "1px solid #c0c0c0", borderRadius: "5px", padding: "20px", marginBottom: "25px", marginTop: "10px" }}>
                        <AvField name="Regex" label="Regex"
                            aria-labelledby="Regex"
                            value={this.props.data.Transform.Regex}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            validate={{async: this.validateRegex}}
                            helpMessage="Regex string that will be applied on attribute"
                        />
                        <AvField name="RegexDelimiter" label="Regex Delimiter"
                            aria-labelledby="Regex Delimiter"
                            value={this.props.data.Transform.RegexDelimiter}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            helpMessage="String that will delimit the regex matches (default: &#34;,&#34;)"
                        />
                        {this.renderRegexExamplePanel()}
                    </div>
                    <div style={{fontWeight: 'bold'}}>Tokenize</div>
                    <div style={{ border: "1px solid #c0c0c0", borderRadius: "5px", padding: "20px", marginBottom: "25px", marginTop: "10px" }}>
                        <AvField name="Delimiters" label="Delimiters"
                            aria-labelledby="Delimiters"
                            value={this.props.data.Transform.Delimiters}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            helpMessage="All the characters to split the string on when tokenizing it (default: &#34; &#34;)"
                        />
                        <AvField name="Token" label="Token" type="number"
                            aria-labelledby="Token"
                            value={this.props.data.Transform.Token}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            style={{width: "20%"}}
                            min={1}
                            max={80000000000000}
                            validate={validate}
                            helpMessage="The 1 based index of the token to grab out of the attribute after splitting it by Delimiters"
                        />
                        <p>
                            <Label check>
                                <AvInput type="checkbox" name="LastToken" aria-labelledby="Once the token is found, include the rest of the string (including other delimiters)" value={this.props.data.Transform.LastToken} disabled={this.props.disabled} onChange={this.onChange} /> Once the token is found, include the rest of the string (including other delimiters)
                            </Label>
                        </p>
                        <p>
                            <Label check>
                                <AvInput type="checkbox" name="IgnoreEmptyTokens" aria-labelledby="Multiple adjacent delimiters should be treated as one" value={this.props.data.Transform.IgnoreEmptyTokens} disabled={this.props.disabled} onChange={this.onChange} /> Multiple adjacent delimiters should be treated as one
                            </Label>
                        </p>
                        {this.renderTokenizeExamplePanel()}
                    </div>
                    <div style={{fontWeight: 'bold'}}>Substring</div>
                    <div style={{ border: "1px solid #c0c0c0", borderRadius: "5px", padding: "20px", marginBottom: "25px", marginTop: "10px" }}>
                        <AvField name="SubStart" label="SubStart" type="number"
                            aria-labelledby="SubStart"
                            value={this.props.data.Transform.SubStart}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            min={-80000000000000}
                            max={80000000000000}
                            style={{width: "20%"}}
                            helpMessage="0-based index of the start of the substring. If negative, the length of the string is added to it. Default value if SubLength is provided: 0"
                        />
                        <AvField name="SubLength" label="SubLength" type="number"
                            aria-labelledby="SubLength"
                            value={this.props.data.Transform.SubLength}
                            disabled={this.props.disabled}
                            onChange={this.onChange}
                            style={{width: "20%"}}
                            helpMessage="Length of substring. If negative, the length of the string is added to it. Defalt value if SubStart is provided: length of the original string"
                        />
                        {this.renderSubstringExamplePanel()}
                    </div>
                    <div className={this.props.disabled? 'disable-input-field' : ''}>
                        <label id="ignore-values-label"> List of values to ignore</label>
                        <MultiInput id="ignore-values" labelId="ignore-values-label"
                        values={this.state.ignore} onChange={this.setIgnoreValues}/>
                        <label id="allow-values-label"> List of values to allow</label>
                        <MultiInput id="allow-values" labelId="allow-values-label"
                        values={this.state.allow} onChange={this.setAllowValues}/>
                    </div>
                </Col>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(OtherTransforms);