// React/MOBX
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { action, runInAction, observable } from 'mobx';
import { observer } from "mobx-react";
import 'date-fns';

// 3rd-party React controls
import Modal from "react-modal";
import Table from "../shared/components/AccessibleTable/Table";
import Select from "../shared/components/AccessibleSelect/Select";
import MultiInput from "../shared/components/AccessibleSelect/MultiInput";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Alert } from "reactstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

// 3rd-party JS libs
import Immutable, { fromJS } from 'immutable';
import { UnregisterCallback } from "history";
import $ from "jquery";

// Project components
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import { getRevisionDateTimeString } from "../shared/helpers/dateTimeHelpers";
import { AudienceTable } from "../AudienceList/AudienceList";
import { NavigationBlocker } from "../shared/components/NavigationBlocker/NavigationBlocker";
import { NavigationBlockerState } from "../shared/components/NavigationBlocker/NavigationBlockerState";

// Project stores
import AlertStore from "../shared/stores/AlertStore";
import { AlertStoreV2 } from "../shared/stores/AlertStore";
import { AlertType, AudienceContext } from "@ts/ts-ux-contracts";
import AppStore from "../shared/stores/AppStore";
import AudienceDetailsStore from "../shared/stores/AudienceDetailsStore";
import AudienceStore from "../shared/stores/AudienceStore";
import CriteriaStore from '../shared/stores/CriteriaStore';
import EstimationStore from "../shared/stores/EstimationStore";
import FunctionalGroupDetailsStore from "../shared/stores/FunctionalGroupDetailsStore";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import GroupsServiceStore from "../shared/stores/GroupsServiceStore";
import UserStore from "../shared/stores/UserStore";
import EmailSubscriptionStore from "../shared/stores/EmailSubscriptionStore";
import QueryBuilderStore from "../shared/stores/QueryBuilderStore";

// Component state/utils
import { AudienceDetailsState, getRevisionString } from "./AudienceDetailsState";

// Styles
import "./AudienceDetails.css";
import { AudienceId, FunctionalGroupPropertiesMask, Attributes, AudienceCriteriaGroup, Audience, CriteriaOperator } from "ts-infra-common";
import AudienceCriteriaService from "../shared/services/AudienceCriteriaService";
import AppInsightsPageTracker from "../shared/services/AppInsightsPageTracker";
import AppInsightsService from "../shared/services/AppInsightsService";

import config from "../config.json";
import { Typography, Grid, Link, LinearProgress, List, ListItem, ListItemText, TableContainer, Paper, TableBody, TableRow, TableHead, TableCell, Table as MaterialTable } from "@material-ui/core";
import { TSQueryBuilder } from "@ts/ts-ux-querybuilder";
import { RingLoader } from "react-spinners";
import { isObject } from "util";
import CircularProgress from "@material-ui/core/CircularProgress";

import isNil from "lodash/isNil";
import { MailFilled } from "@ant-design/icons";

@observer
export class AudienceDetails extends React.Component<RouteComponentProps<{ functionalGroupId: string, audience: string, revision?: string }>, AudienceDetailsState> {
    pageLoadTracker: AppInsightsPageTracker;
    unregisterBackListener: UnregisterCallback;
    queryBuilderComponent;
    @observable narratorHidden: boolean = true;
    groupAlertStore: AlertStoreV2 = new AlertStoreV2();
    constructor(props) {
        super(props);
        this.state = new AudienceDetailsState();
        this.queryBuilderComponent = React.createRef();
        AlertStore.clearAll();
    }

    onClickUnlinkSelected(audience: AudienceId) {
        AppInsightsService.trackEvent("UnlinkClicked");
        const parentFG = AudienceDetailsStore.data.FunctionalGroup;
        const parentName = AudienceDetailsStore.data.ShortName;
        const childFG = audience.FunctionalGroup;
        const childName = audience.AudienceName;
        if (window.confirm(`Confirm unlink audience ${parentName} and audience ${childName}`)) {
            AudienceDetailsStore.UnlinkAudience(parentFG, parentName, childFG, childName)
                .then(() => {
                    if (AudienceDetailsStore.unlinkOp.success) { this.reRender(); }
                });
        }
    }

    fetchAudience() {
        const audienceOp = AudienceDetailsStore.fetchAudienceDetails(this.props.match.params.functionalGroupId, this.props.match.params.audience, this.props.match.params.revision, true);
        audienceOp.then(() => {
            this.state.updateAudienceOwner(AudienceDetailsStore.data.Owner);
            this.state.updateAudienceAdmins(this.removeArrayDuplicates(FunctionalGroupDetailsStore.data.Owners || [], AudienceDetailsStore.data.Admins || []));
            this.state.updateOriginalOwnerAndAdmins(this.state.audienceOwner, this.state.currentAdmins);
            this.state.updateAudienceLabels(AudienceDetailsStore.data.Labels);
            this.displayExpiryBanner();
            this.state.setNavigationBlocker(false);
        });
        return audienceOp;
    }

    componentDidMount() {
        this.pageLoadTracker = AppInsightsService.startTrackPage("AudienceDetails");
        AppStore.setPageTitle("Audience Details");
        this.state.updateRevisionModalIsOpen(false);
        FunctionalGroupDetailsStore.fetchFunctionalGroupName(this.props.match.params.functionalGroupId, true).then(() => {
            QueryBuilderStore.setFunctionalGroupAppId(FunctionalGroupDetailsStore.data.GroupsAppId);
            const audienceOp = this.fetchAudience();
            const criteriaOp = CriteriaStore.fetchCriteria(this.props.match.params.functionalGroupId, false);
            criteriaOp.then(() => {
                // Add hardcoded "globally supported" attributes
                if (CriteriaStore.data) {
                    CriteriaStore.data.push({
                        "id": "CosmosStreamTxt",
                        "Kind": 34,
                        "Provider": 0,
                        "Name": "CosmosStreamTxt",
                        "NameLower": "cosmosstreamtxt",
                        "Description": "CosmosStreamTxt",
                        "AreValuesStatic": false,
                        "NamespaceWithName": "CosmosStreamTxt",
                        "Required": false,
                        "Disabled": false,
                        "ApplicableOperators": [1, 12, 13],
                        "AttributePropertiesKind": 0,
                        "IsFallBack": false,
                        "ApprovalState": 0,
                        "UserRole": 0,
                        "IsCtacAttribute": true,
                        "Example": null,
                        "Platform": null,
                        "Source": null,
                        "RejectionReason": null,
                        "Justification": null,
                        "ValidValues": null,
                        "GroupsName": null,
                        "AttributeProperties": null,
                        "FallbackName": null,
                        "Aliases": null,
                        "TelemetryAliases": null,
                        "LegacyNames": null,
                        "TransformNames": null,
                        "AddToCollectionRequests": null,
                        "Changer": null,
                        "Revision": null
                    });
                    CriteriaStore.data.push({
                        "id": "e787a1be-87d3-43bc-acb0-fbb868d9da47",
                        "Kind": 47,
                        "Provider": 0,
                        "Name": "GenericBulkIngestion",
                        "NameLower": "genericbulkingestion",
                        "Description": "Bulk ingestion of a group",
                        "AreValuesStatic": false,
                        "NamespaceWithName": "GenericBulkIngestion",
                        "Required": false,
                        "Disabled": false,
                        "ApplicableOperators": [
                          1,
                          12,
                          13
                        ],
                        "AttributePropertiesKind": 16,
                        "AttributeProperties": {
                          "AttributeName": "GenericBulkIngestion"
                        },
                        "IsFallBack": false,
                        "ApprovalState": 2,
                        "UserRole": 100,
                        "IsCtacAttribute": false,
                        "Revision": "0638163929122135646",
                        "Example": null,
                        "Platform": null,
                        "Source": null,
                        "RejectionReason": null,
                        "Justification": null,
                        "ValidValues": null,
                        "GroupsName": null,
                        "FallbackName": null,
                        "Aliases": null,
                        "TelemetryAliases": null,
                        "LegacyNames": null,
                        "TransformNames": null,
                        "AddToCollectionRequests": null,
                        "Changer": null,
                      });
                }
                this.state.criteriaStore.set(CriteriaStore.data);
                audienceOp.then(() => {
                    this.updateQueriesFromAudienceData(true);
                });
            });
        });
        this.updateAudienceEstimates();
        Modal.setAppElement(document.getElementById("root"));
        this.state.updateUser(UserStore.username.replace(/@.+/, ''));
        if (!EmailSubscriptionStore.isLoaded) EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true);

        if (AudienceTable.hasMounted) {
            this.unregisterBackListener = this.props.history.listen(location => {
                if (location.pathname.indexOf("/audience/details") !== -1 && this.props.history.action === "POP") {
                    this.props.history.goBack();
                }
            });
        }
    }

    componentWillUnmount() {
        if (this.unregisterBackListener) {
            this.unregisterBackListener();
        }
    }

    @action
    private updateAudienceEstimates() {
        EstimationStore.data = null;
        EstimationStore.fetchEstimate(this.props.match.params.functionalGroupId, this.props.match.params.audience, true, this.props.match.params.revision).then(() => {
            this.state.updateEstimatesIsLoaded(true);
        });
    }

    @action
    private updateQueriesFromAudienceData(update: boolean) {
        this.state.updateQueryReady(false);
        this.state.setCriteria(AudienceDetailsStore.data.Criteria);
        this.state.updateWorkingQuery(JSON.stringify(AudienceDetailsStore.data.Criteria));
        // Running this.state.updateQueryReader(true) separately works in all cases, whereas
        // running it in the same context as updateQueriesFromAudienceData can cause the QueryBuilder component
        // to not re-render in some cases.
        // Re-render is required when you want QueryBuilder to forget user input (as in the reset button).
        setTimeout(() => {
            this.state.updateQueryReady(true);
        }, 1);
    }

    private getAttrDisplay(attributes: Attributes) {
        const disabled = attributes & Attributes.Disabled;
        const deleted = attributes & Attributes.Deleted;
        return `${disabled ? '(Disabled)' : ''}${deleted ? '(Deleted)' : ''}`;
    }

    render() {
        if (!AudienceDetailsStore.isLoaded || AudienceDetailsStore.isLoading || !FunctionalGroupDetailsStore.isLoaded || FunctionalGroupDetailsStore.isLoading || AudienceDetailsStore.isLinking || AudienceDetailsStore.isUnlinking) {
            return <div style={{ paddingTop: "100px" }}><LoadingIndicator text="Loading..." /></div>
        }

        if (this.pageLoadTracker) {
            this.pageLoadTracker.stop();
        }

        const isFGEditInPlace = (FunctionalGroupDetailsStore.data.Properties & FunctionalGroupPropertiesMask.EditAudienceGroupInPlace) !== 0;
        const audienceDetails = AudienceDetailsStore.data;
        const audienceChildren = audienceDetails.Children;
        const functionalGroupDetailsData = FunctionalGroupDetailsStore.data;
        const functionalGroups = !FunctionalGroupStore.data ? [] : FunctionalGroupStore.data.slice().sort((a, b) => a.Name > b.Name ? 1 : -1).map((i) => <option key={i.Name} value={i.Name}>{i.Name}</option>);
        const audiencesSelect = !AudienceStore.data ? [] : AudienceStore.data.slice().sort((a, b) => a.ShortName > b.ShortName ? 1 : -1).map((i) => <option key={i.ShortName} value={i.ShortName}>{i.ShortName}</option>);
        const revisionHistory = (!AudienceDetailsStore.isLoading && AudienceDetailsStore.isLoaded && audienceDetails.History) ?
            audienceDetails.History.map((i) => ({
                label: `${i.Revision} => ${getRevisionDateTimeString(i.Revision)}`,
                value: i.Revision,
                Revision: i.Revision,
                Created: getRevisionDateTimeString(i.Revision),
                Changer: i.Changer
            }))
            : [];
        const functionalGroupIds = audienceDetails.FunctionalGroupAudience ? audienceDetails.FunctionalGroupAudience.GroupServicesId : [];
        const showEstimateWarnings = this.state.estimatesIsLoaded && EstimationStore.data && (EstimationStore.data.Warnings.length > 0 || EstimationStore.data.Errors.length > 0);
        const expiring = AudienceDetailsStore.isLoaded && AudienceDetailsStore.data.RevisionInfo && AudienceDetailsStore.data.RevisionInfo.ExpirationDate;
        const disableSave = this.saveDisabled() && !expiring;
        const saving = (AudienceDetailsStore.httpPatch && AudienceDetailsStore.httpPatch.inProgress) || (AudienceDetailsStore.httpPostCriteria && AudienceDetailsStore.httpPostCriteria.inProgress)
            || (AudienceDetailsStore.renewExpOp && AudienceDetailsStore.renewExpOp.inProgress);
        const subscriptionId = `${AudienceDetailsStore.data.FunctionalGroup}|${AudienceDetailsStore.data.ShortName}`;
        const audienceCriteriaRows = [];
        const addLinkDisabled = AudienceDetailsStore.isLinking || AudienceDetailsStore.isUnlinking;
        if (AudienceDetailsStore.data && AudienceDetailsStore.data.FunctionalGroupAudience && AudienceDetailsStore.data.FunctionalGroupAudience.Criteria && AudienceDetailsStore.data.FunctionalGroupAudience.Criteria.Criteria) {
            // TODO: This matches the original Inherited Criteria logic, though the limitations are obvious
            AudienceDetailsStore.data.FunctionalGroupAudience.Criteria.Criteria.map((v) => {
                audienceCriteriaRows.push({
                    Name: v.Type.Name,
                    Operator: CriteriaOperator[v.Operator],
                    Value: v.Type.AreValuesStatic ? v.Value.split(',') : v.Value
                });
            });
        }
        if (this.state.leftRevision && this.state.rightRevision) {
            if (this.state.lastTrackedDiffRevisions && this.state.lastTrackedDiffRevisions[0] !== this.state.nextDiffRevisions[0] && this.state.lastTrackedDiffRevisions[1] !== this.state.nextDiffRevisions[1]) {
                AppInsightsService.trackEvent("AudienceRevisionDiffRequested");
                this.state.updateLastTrackedDiffRevisions();
            }
        }
        const audienceContext: AudienceContext = {
            audience: AudienceDetailsStore.data,
            groupsAppId: FunctionalGroupDetailsStore.data ? FunctionalGroupDetailsStore.data.GroupsAppId ?? config.defaultGroupsApp : null
        };
        const showEstimationWarningLink = !EstimationStore.isLoading && EstimationStore.data && ((EstimationStore.data.Warnings && EstimationStore.data.Warnings.length > 0) || (EstimationStore.data.Errors && EstimationStore.data.Errors.length > 0));
        return (
            <div>
                <NavigationBlocker state={this.state.navigationBlockerState} message={"You have pending unsaved changes. Are you sure you want to leave?"} />
                <Dialog onClose={() => {
                    this.state.setExpSelectIsOpen(false);
                }} aria-labelledby="exp-date-dialog-title" open={this.state.expSelectIsOpen}>
                    <DialogTitle id="exp-date-dialog-title">Select a new expiration date</DialogTitle>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="exp-date-picker-inline"
                                label="New Expiration Date"
                                value={this.state.renewDate}
                                onChange={(d) => { this.state.setRenewDate(d); }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <div className="save-row">
                        <div className={"save-row-item"}>
                            <Button className="ts-btn" onClick={() => { this.cancelRenew(); }}>Cancel</Button>
                        </div>
                        <div className={"save-row-item"} id="tsUndoChangesCell">
                            <Button className="ts-btn" onClick={() => { this.commitRenew(); }}>OK</Button>
                        </div>
                    </div>
                </Dialog>
                <Dialog onClose={() => {
                    this.state.setCriteriaCheckIsOpen(false);
                }} aria-labelledby="criteria-check-dialog-title" open={this.state.criteriaCheckIsOpen} fullWidth={true} maxWidth='xl'>
                    <DialogTitle id="criteria-check-dialog-title">Warning: Possibly incorrect audience being submitted</DialogTitle>
                    <Typography className="safety-check-description">The audience object that is being submitted doesn't match the expression tree in the query builder. Either pick the correct expression tree below to update, or refresh the page and redo your changes. An alert has been generated, and an engineer will log a bug.</Typography>
                    <Grid
                        container
                        direction="row"
                    >
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Typography variant="h6">Pending Changes</Typography>
                                <TSQueryBuilder criteria={this.state.pendingChangesCriteria} tsQueryConfig={this.state.tsQueryConfig} readOnly={true} audienceContext={audienceContext} />
                                <Button variant="contained" className="ts-btn" onClick={() => {
                                    this.state.safetyCheckUpdater(this.state.pendingChangesCriteria);
                                    this.state.setCriteriaCheckIsOpen(false);
                                }}>Save this one</Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Typography variant="h6">Changes from Expression Builder</Typography>
                                <TSQueryBuilder criteria={this.state.qbCriteria} tsQueryConfig={this.state.tsQueryConfig} readOnly={true} audienceContext={audienceContext} />
                                <Button variant="contained" className="ts-btn" onClick={() => {
                                    this.state.safetyCheckUpdater(this.state.qbCriteria);
                                    this.state.setCriteriaCheckIsOpen(false);
                                }}>Save this one</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="save-row">
                        <div className={"save-row-item"}>
                            <Button className="ts-btn" onClick={() => { this.state.setCriteriaCheckIsOpen(false); }} variant="outlined">Cancel</Button>
                        </div>
                    </div>
                </Dialog>
                <div className="audience-metadata" role="form" aria-labelledby="details-header">
                    <h2 className="details-header" id="details-header"> AUDIENCE METADATA </h2>
                    <div>
                        <label> Revision </label> <a aria-label="View all audience's revisions" tabIndex={0} className="view-revisions" href="javascript:void(0)" onKeyPress={event => { if (event.key === "Enter") { this.openRevisionModal() } }} onClick={this.openRevisionModal}>(View All)</a>
                        <div>{(this.props.match.params.revision) ? this.props.match.params.revision : audienceDetails.TimeStamp} {audienceDetails.RevisionInfo && audienceDetails.RevisionInfo.IsDraft ? "(Draft)" : null}</div>
                        <Dialog open={this.state.addLinkModalIsOpen} onClose={this.closeAddLinkModal} aria-labelledby="add-link-dialog-title" fullWidth={true} maxWidth='md'
                            aria-modal={true}>
                            <DialogTitle id="add-link-dialog-title">Add Link</DialogTitle>
                            {(!FunctionalGroupStore.isLoaded || FunctionalGroupStore.isLoading) ? <div style={{ paddingTop: "100px" }}><LoadingIndicator text="Loading..." /></div> :
                                <div aria-hidden={this.narratorHidden} style={{ margin: '20px' }}>
                                    <h5 id="heading" className="modal-header"> CHILD AUDIENCE INFORMATION </h5>
                                    <div id="full_description">
                                        <AvForm aria-live="assertive" className="create-form" onValidSubmit={() => this.onLink()}>
                                            <AvField type="select" name="functionalGroup" aria-label="Select child's functional group" label="Functional Group" id="functionalGroup" onChange={this.onChangeFunctionalGroup}
                                                value={this.state.selectedFunctionalGroupId} validate={{ required: { value: this.state.selectedFunctionalGroupId !== "-1" } }} errorMessage="Please select a functional group from the dropdown.">
                                                <option key="-1" value="-1">Select a Group</option>
                                                {functionalGroups}
                                            </AvField>
                                            <AvField aria-live="assertive" type="select" name="audienceSelected" aria-label="Select child's audience name" label="Audience name" id="audienceSelected" onChange={this.onChangeAudienceSelected} disabled={this.state.selectAudienceIsDisabled}
                                                value={this.state.selectedAudience} validate={{ required: { value: this.state.selectedAudience !== "-1" } }} errorMessage="Please select an audience from the dropdown.">
                                                <option key="-1" value="-1">Select an Audience</option>
                                                {audiencesSelect}
                                            </AvField>
                                            <AvGroup className="button" style={{ float: "right" }}>
                                                <Button color="secondary" className="ts-btn" onClick={this.closeAddLinkModal}>Close</Button>
                                                <Button color="primary" className={`btn ts-btn ${addLinkDisabled ? 'ts-btn-disabled' : ''}`} disabled={addLinkDisabled} onClick={this.onLink}>
                                                    {AudienceDetailsStore.isLinking ? 'Linking...' : 'Add Link'}
                                                </Button>
                                            </AvGroup>
                                        </AvForm>
                                    </div>
                                </div>
                            }
                        </Dialog>
                        <Dialog open={this.state.revisionModalIsOpen} onClose={this.closeRevisionModal} aria-labelledby="revision-dialog-title" fullWidth={true} maxWidth='md'
                            aria-modal={true}>
                            <DialogTitle id="revision-dialog-title">AUDIENCE DIFF VIEWER</DialogTitle>
                            <Grid container direction="column" justify="center" alignContent="center">
                                <Button className="ts-btn" onClick={this.closeRevisionModal}>Close</Button>
                                <div id="full_description">
                                    <AvForm>
                                        <label id="audience-revision-compare-left-label" className="select-rev" aria-label="Select left revision to compare">Left revision:</label>
                                        <Select id="audience-revision-compare-left" labelId="audience-revision-compare-left-label" width="100%"
                                            clearable={true} options={revisionHistory} value={this.state.selectedLeftRevision} onChange={this.leftRevChanged} expandOnClick={true}
                                        />

                                        <label id="audience-revision-compare-right-label" className="select-rev" aria-label="Select right revision to compare">Right revision:</label>
                                        <Select id="audience-revision-compare-right" labelId="audience-revision-compare-right-label" width="100%"
                                            clearable={true} options={revisionHistory} value={this.state.selectedRightRevision} onChange={this.rightRevChanged} expandOnClick={true}
                                        />
                                    </AvForm>
                                    {
                                        this.state.isFetchingRevision ? <LoadingIndicator text="Loading..." /> :
                                            <ReactDiffViewer
                                                oldValue={getRevisionString(this.state.leftRevision)}
                                                newValue={getRevisionString(this.state.rightRevision)}
                                                compareMethod={DiffMethod.WORDS}
                                                splitView={true}
                                                styles={{
                                                    line: {
                                                        wordBreak: 'break-word',
                                                    }
                                                }} />
                                    }

                                    <h5 className="modal-header">REVISION LIST</h5>
                                    <Table title="" name="Revisions table"
                                        columns={[
                                            { label: 'Revision', field: 'Revision', type: 'string', sortOptions: { sortable: true }, filterOptions: { filterable: true, filterType: 'search' }, renderDataFn: this.renderRevisionLink },
                                            { label: 'Created', field: 'Created', type: 'datetime', sortOptions: { sortable: true }, filterOptions: { filterable: true, filterType: 'search' } },
                                            { label: 'Changer', field: 'Changer', type: 'string', sortOptions: { sortable: true }, filterOptions: { filterable: true, filterType: 'select' } },
                                        ]}
                                        data={revisionHistory}
                                        paginationOptions={{ paginateTable: true, rowsPerPageChoices: [10, 50] }}
                                    />
                                </div>
                            </Grid>
                        </Dialog>
                        <Dialog open={this.state.previewAudienceSizeModalIsOpen} onClose={this.closePreviewAudienceSizeModal} aria-labelledby="preview-audiencesize-dialog-title" fullWidth={true} maxWidth='md'
                            aria-modal={true}>
                            <Grid container direction="column" justify="center" alignContent="center">
                                <DialogTitle id="preview-audiencesize-title">Preview Audience Size</DialogTitle>
                                {!EstimationStore.onDemandEstimationOp || EstimationStore.onDemandEstimationOp.inProgress ? <LoadingIndicator text="Running audience size estimation. This can take up to 3 minutes." /> :
                                    EstimationStore.onDemandEstimationOp.success ?
                                        <Grid container item justify="center" alignContent="center">
                                            {EstimationStore.onDemandEstimationOp.data.Errors.length > 0 ? <List>
                                                {EstimationStore.onDemandEstimationOp.data.Errors.map(i => <ListItem className="estimation-warning">
                                                    <ListItemText primary={i} />
                                                </ListItem>)}
                                            </List> : null}
                                            {EstimationStore.onDemandEstimationOp.data.Warnings.length > 0 ? <List>
                                                {EstimationStore.onDemandEstimationOp.data.Warnings.map(i => <ListItem className="estimation-warning">
                                                    <ListItemText primary={i} />
                                                </ListItem>)}
                                            </List> : null}
                                            <TableContainer component={Paper} className="estimation-preview-table">
                                                <MaterialTable>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">Platform</TableCell>
                                                            <TableCell align="center">Count</TableCell>
                                                            <TableCell align="center">Delta</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.getAudienceEstimates(EstimationStore.onDemandEstimationOp.data.PlatformResult).map((row) => {
                                                            if (EstimationStore.data) {
                                                                const oldData = this.getAudienceEstimates(EstimationStore.data.PlatformResult);
                                                                const item = oldData.find(v => v[0] === row[0]);
                                                                let diff = parseInt(row[1], null) - (item ? parseInt(item[1], null) : 0);
                                                                let prefix = "+";
                                                                if (diff < 0) {
                                                                    prefix = "-";
                                                                    diff = Math.abs(diff);
                                                                }

                                                                return <TableRow key={row[0]}>
                                                                    <TableCell component="th" scope="row" align="center">
                                                                        {row[0]}
                                                                    </TableCell>
                                                                    <TableCell align="center">{row[1]}</TableCell>
                                                                    <TableCell align="center">{prefix}{diff}</TableCell>
                                                                </TableRow>;
                                                            } else {
                                                                return <TableRow key={row[0]}>
                                                                    <TableCell component="th" scope="row" align="center">
                                                                        {row[0]}
                                                                    </TableCell>
                                                                    <TableCell align="center">{row[1]}</TableCell>
                                                                    <TableCell align="center"></TableCell>
                                                                </TableRow>;
                                                            }
                                                        })}
                                                    </TableBody>
                                                </MaterialTable>
                                            </TableContainer>
                                        </Grid>
                                        : `Failed to load estimates: ${EstimationStore.onDemandEstimationOp.errorMessage}`}
                                <Button className="ts-btn" onClick={this.closePreviewAudienceSizeModal}>Close</Button>
                            </Grid>
                        </Dialog>
                        <label> Functional Group </label>
                        <div className="word-wrap-break-word">{this.props.match.params.functionalGroupId}</div>
                        <label> Audience Name </label>
                        <div className="word-wrap-break-word">{`${this.props.match.params.audience} ${this.getAttrDisplay(AudienceDetailsStore.data.Attributes)}`}</div>
                        <AvForm>
                            <label id="audience-labels-label" className="audience-labels-select" aria-label="audience labels"> Audience Labels {this.state.isLabelsDirty ? <Typography style={{ color: 'red' }} variant="caption">(Unsaved Changes)</Typography> : null}</label>
                            <MultiInput id="audience-labels" labelId="audience-labels-label"
                                values={this.state.labelNames} onChange={this.setLabels}
                            />
                        </AvForm>
                        <label> Inherited Group ID(s) </label>
                        {functionalGroupIds.length === 0
                            ? <div>none</div>
                            : <ul>{functionalGroupIds.map(i => <li key={i}><a tabIndex={0} className="groupServiceLink" href="javascript:void(0)" aria-label={`See group ${i} details`}
                                onKeyPress={event => { if (event.key === "Enter") { this.openGroupsServiceIdModal(i) } }} onClick={() => this.openGroupsServiceIdModal(i)}>{i}</a></li>)}</ul>}
                        <label> Group ID(s) </label>
                        {audienceDetails.GroupsIds && audienceDetails.ExpressionIds && (audienceDetails.GroupsIds.length !== 0 || audienceDetails.ExpressionIds.length !== 0) ? audienceDetails.RevisionInfo && audienceDetails.RevisionInfo.IsDraft ? <ul><li className="estimation-warning" key="draft-aud-warning">One or more groups may not reflect this revision's criteria. Select "Save All Changes" to commit the draft criteria.</li></ul> : null : null}
                        {(!audienceDetails.GroupServicesId || audienceDetails.GroupServicesId.length === 0)
                            ? <div>none</div>
                            : <ul>{this.removeArrayDuplicates(functionalGroupIds, audienceDetails.GroupServicesId.filter(a => !a.includes("Batch"))).map(i => <li key={i}><a tabIndex={0} href="javascript:void(0)" className="groupServiceLink" aria-label={`See group ${i} details`}
                                onKeyPress={event => { if (event.key === "Enter") { this.openGroupsServiceIdModal(i) } }} onClick={() => this.openGroupsServiceIdModal(i)}>{i}</a></li>)}</ul>}
                        <Dialog open={this.state.groupsServiceIdModalIsOpen} onClose={this.closeGroupsServiceIdModal} aria-labelledby="group-def-dialog-title" fullWidth={true} maxWidth='md'>
                            <DialogTitle id="group-def-dialog-title">Group Definition</DialogTitle>
                            <Grid container direction="column" alignItems="center" justify="center">
                                {this.groupAlertStore.alerts.map((alert, i) => <Alert key={`alert-${i}`} style={{ width: "100%" }} toggle={() => this.groupAlertStore.clear(alert)} color={this.groupAlertStore.getAlertColor(alert.type)}>{AlertType[alert.type].toUpperCase()}: {alert.message}</Alert>)}
                                {!GroupsServiceStore.isLoading && GroupsServiceStore.http && !GroupsServiceStore.http.success ? <Typography>{`Sorry, we couldn't find a group with ID ${this.state.selectedGroupsServiceId} in groups app ${FunctionalGroupDetailsStore.data.GroupsAppId ?? config.defaultGroupsApp}.`}</Typography> : (!GroupsServiceStore.isLoaded || GroupsServiceStore.isLoading || !this.state.groupsServiceIdDetails)
                                    ? <LoadingIndicator text="Loading..." />
                                    : <div><pre>{JSON.stringify(this.state.groupsServiceIdDetails, null, 2)}</pre></div>}
                                <Button className="ts-btn" onClick={this.closeGroupsServiceIdModal}>Close</Button>
                            </Grid>
                        </Dialog>
                        <label> Audience Size Estimates </label> {showEstimationWarningLink ? <a aria-label="View audience estimation warnings" tabIndex={0} href="javascript:void(0)" className={`view-revisions ${!showEstimateWarnings ? 'hidden' : null}`} onKeyPress={event => { if (event.key === "Enter") { this.onClickEstimateWarning() } }} onClick={this.onClickEstimateWarning}>(View Warnings)</a> : null}
                        <div aria-live="assertive">
                            {(this.state.expand && EstimationStore.data) && <ul>{EstimationStore.data.Warnings.map(i => <li className="estimation-warning" key={i}>{i}</li>)}{EstimationStore.data.Errors.map(i => <li className="estimation-warning" key={i}>{i}</li>)}</ul>}
                        </div>
                        {this.state.estimatesIsLoaded
                            ? EstimationStore.http.success && EstimationStore.data ? <ul>{this.getAudienceEstimates(EstimationStore.data.PlatformResult).map(i => <li key={i[0]}>{`${i[0]}: ${i[1]}`}</li>)}</ul>
                                : <div className="estimation-warning">{`Estimation failed to run. If the query took a long time and failed, it probably timed out on the Kusto side. If not, email '${config.helpAlias}'.`}</div>
                            : <div><CircularProgress /></div>}
                        <label> Parent Audiences (Linked) </label>
                        {(!!!audienceDetails.Parents || audienceDetails.Parents.length === 0)
                            ? <div>none</div>
                            : <ul>{audienceDetails.Parents.map(i => <li key={i.AudienceName}><a aria-label={`Link to parent audience ${i.AudienceName}`} tabIndex={0} className="groupServiceLink" href={`/audience/details/functionalgroup/${i.FunctionalGroup}/audience/${i.AudienceName}`}>{i.AudienceName}</a></li>)}</ul>}
                    </div>
                    <AvForm>
                        <label id="audience-owner-label" className="audience-admins-select" htmlFor="audienceOwner"> Audience Owner {this.state.isOwnerDirty ? <Typography style={{ color: 'red' }} variant="caption">(Unsaved Changes)</Typography> : null}</label>
                        <div id="audience-owner-textbox" className="audience-metadata-no-padding"><AvField name="audienceOwner" id="audienceOwner" value={this.state.audienceOwner} onChange={this.onChangeAudienceOwner} /></div>
                        <label id="audience-admins-label" className="audience-admins-select"> Audience Admin(s) {this.state.isAdminsDirty ? <Typography style={{ color: 'red' }} variant="caption">(Unsaved Changes)</Typography> : null}</label>
                        <MultiInput id="audience-admins" labelId="audience-admins-label"
                            values={this.state.currentAdmins} onChange={this.setAdmins}
                        />
                    </AvForm>
                    <label> Inherited Admin(s) </label>
                    {(functionalGroupDetailsData.Owners.length === 0)
                        ? <div>none</div>
                        : <div>{this.getInheritedAdmins(functionalGroupDetailsData.Owners)}</div>}
                    {!EmailSubscriptionStore.data || (EmailSubscriptionStore.httpPost && EmailSubscriptionStore.httpPost.inProgress) ? <LoadingIndicator text="Loading..." /> : EmailSubscriptionStore.data.indexOf(subscriptionId) > -1 ?
                        <Button variant="contained" className="ts-btn" onClick={this.onUnsubscribe}>Unsubscribe <MailFilled type="mail" className="listIcon" /></Button> :
                        <Button variant="contained" className="ts-btn" onClick={this.onSubscribe}>Subscribe <MailFilled type="mail" className="listIcon" /></Button>
                    }
                </div>
                <div className="audience-definition">
                    <h2 className="details-header" id="definition-header"> AUDIENCE DEFINITION </h2>{audienceDetails.RevisionInfo && audienceDetails.RevisionInfo.IsDraft ? <Typography style={{ color: 'red' }} > Draft</Typography> : null}
                    {
                        this.state.missingAttributes && this.state.missingAttributes.length > 0 && (!AudienceDetailsStore.httpPostCriteria || !AudienceDetailsStore.httpPostCriteria.inProgress) ? this.renderMissingAttributesWarning() : ""
                    }
                    {
                        (this.state.queryReady && this.state.criteriaStore.data) ?
                            saving ? <div><Typography variant="h6">Saving {this.state.savingState}...</Typography><LinearProgress /></div> :
                                <div id={`audience-query-builder-${AudienceDetailsStore.data.Revision}`} role="form" aria-labelledby="definition-header">
                                    {Boolean(this.props.match.params.revision) ? <Typography variant="subtitle2">Note: Editing of specific revisions is disabled. To make changes, <Link href={`/audience/details/functionalGroup/${this.props.match.params.functionalGroupId}/audience/${this.props.match.params.audience}`}>go back to the latest audience page.</Link></Typography> : null}
                                    <TSQueryBuilder readOnly={Boolean(this.props.match.params.revision)} criteria={this.state.criteria} tsQueryConfig={this.state.tsQueryConfig} onCriteriaChanged={(c) => {
                                        if (c) {
                                            this.state.updateWorkingQuery(c);
                                            this.state.updateNavigationBlocker();
                                        }
                                    }} onMissingAttribute={(n) => {
                                        this.state.addMissingAttribute(n);
                                    }} andOperatorToggledOnWithSingleCriterion={true} ref={this.queryBuilderComponent} onFormValidationChanged={(formValid) => {
                                        this.state.setQueryBuilderFormValid(formValid);
                                    }} audienceContext={audienceContext} />
                                    {Boolean(this.props.match.params.revision) ? null : <div className="save-row">
                                        <div className={"save-row-item"}>
                                            <input aria-label="Apply changes to published content" className={"inPlaceCheckBox"} type={"checkbox"} checked={isFGEditInPlace || this.state.editAudInPlace} onChange={this.onChangeInPlaceCheckbox} /><label> Apply changes to published content</label>
                                        </div>
                                    </div>}
                                    {Boolean(this.props.match.params.revision) ? null : <div className="save-row">
                                        {expiring ? <div className={"save-row-item"} id="tsRenewButtonCell">
                                            <Button variant="contained" className={`btn ts-btn ${disableSave ? 'ts-btn-disabled' : ''}`} disabled={disableSave} onClick={this.renew}>{saving ? 'Renewing' : 'Renew'}</Button>
                                        </div> : null}
                                        <div className={"save-row-item"} id="tsSaveButtonCell">
                                            <Button variant="contained" className={`btn ts-btn ${disableSave ? 'ts-btn-disabled' : ''}`} disabled={disableSave} onClick={this.saveChanges}>{saving ? 'Saving' : 'Save all changes'}</Button>
                                        </div>
                                        <div className={"save-row-item"} id="tsPreviewAudienceSizeCell">
                                            <Button variant="contained" className={`btn ts-btn ${disableSave ? 'ts-btn-disabled' : ''}`} disabled={disableSave} onClick={this.startPreviewAudienceSize}>Preview audience size</Button>
                                        </div>
                                        <div className={"save-row-item"} id="tsUndoChangesCell">
                                            <Button variant="contained" className={`btn ts-btn ${disableSave ? 'ts-btn-disabled' : ''}`} disabled={disableSave} onClick={() => this.undoChanges()}>Undo changes (reset to last saved audience)</Button>
                                        </div>
                                    </div>}
                                </div>
                            : <div><Typography variant="h6">Loading criteria...</Typography><LinearProgress /></div>
                    }
                    <div className="inherited-audiences">
                        {Boolean(this.props.match.params.revision) ? null : <Button variant="contained" className="ts-btn" onKeyPress={event => { if (event.key === "Enter") { this.openAddLinkModal() } }} onClick={() => this.openAddLinkModal()}>Add new link</Button>}
                        <Table title={<h3 className="details-header"> INHERITED AUDIENCES </h3>} name="Inherited audiences table"
                            columns={[
                                { label: 'Functional Group', field: 'FunctionalGroup', type: 'string' },
                                { label: 'Audience Name', field: 'AudienceName', type: 'string', renderDataFn: this.renderAudienceLink },
                                { label: 'Action', field: 'Unlink', type: 'custom', renderDataFn: this.renderUnlinkButton },
                            ]}
                            data={audienceChildren}
                            paginationOptions={{ paginateTable: true, rowsPerPageChoices: [10, 25, 50] }}
                        />
                    </div>
                    <div className="inherited-criteria">
                        <Table title={<h3 className="details-header"> INHERITED CRITERIA </h3>} name="Inherited criteria table"
                            columns={[
                                { label: 'Name', field: 'Name', type: 'string' },
                                { label: 'Operator', field: 'Operator', type: 'string' },
                                { label: 'Value', field: 'Value', type: 'string' },
                            ]}
                            data={audienceCriteriaRows}
                        />
                    </div>
                </div>
            </div>
        )
    }

    private renderRevisionLink = (revision: string) => {
        return <RouterLink to={`/audience/details/functionalGroup/${this.props.match.params.functionalGroupId}/audience/${this.props.match.params.audience}/revision/${revision}`} onClick={() => this.handleRevisionOnClick(revision)}>
            {revision}
        </RouterLink>;
    }

    private renderAudienceLink = (name: string, audience: AudienceId) => {
        return <a className="groupServiceLink" href={`/audience/details/functionalgroup/${audience.FunctionalGroup}/audience/${name}`}>{name}</a>
    }

    private renderUnlinkButton = (_cell, audience: AudienceId) => {
        return (
            <button
                className="unlink-btn btn btn-secondary"
                type="button"
                disabled={AudienceDetailsStore.isUnlinking || AudienceDetailsStore.isLinking}
                onClick={() => this.onClickUnlinkSelected(audience)}
                onKeyPress={event => { if (event.key === "Enter") { this.onClickUnlinkSelected(audience) } }}
            >
                {AudienceDetailsStore.isUnlinking && (AudienceDetailsStore.unlinkOp.query.childName === audience.AudienceName) ? 'Unlinking...' : 'Unlink'}
            </button>
        )
    }

    @action
    private onChangeInPlaceCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.state.updateEditAudInPlace(e.target.checked);
    }

    @action
    private onSubscribe = async () => {
        AppInsightsService.trackEvent("SubscribeClicked");
        await EmailSubscriptionStore.SubscribeUser(`${AudienceDetailsStore.data.FunctionalGroup}|${AudienceDetailsStore.data.ShortName}`, AudienceDetailsStore.data.FunctionalGroup, AudienceDetailsStore.data.ShortName)
            .finally(() => { EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true) });
    }

    @action
    private onUnsubscribe = async () => {
        AppInsightsService.trackEvent("UnsubscribeClicked");
        await EmailSubscriptionStore.UnsubscribeUser(`${AudienceDetailsStore.data.FunctionalGroup}|${AudienceDetailsStore.data.ShortName}`, AudienceDetailsStore.data.FunctionalGroup, AudienceDetailsStore.data.ShortName)
            .finally(() => { EmailSubscriptionStore.fetchAllUserSubscriptions(this.state.currentUser, true) });
    }

    @action
    private onLink = () => {
        AppInsightsService.trackEvent("AddLinkClicked");
        AlertStore.clearAll();
        if (!!this.state.selectedFunctionalGroupId && !!this.state.selectedAudience
            && (this.state.selectedFunctionalGroupId.localeCompare("-1") !== 0 || this.state.selectedFunctionalGroupId.localeCompare('') !== 0)
            && (this.state.selectedAudience.localeCompare("-1") !== 0 || this.state.selectedAudience.localeCompare('') !== 0)) {
            const parentFG = AudienceDetailsStore.data.FunctionalGroup;
            const parentName = AudienceDetailsStore.data.ShortName;
            const childFG = this.state.selectedFunctionalGroupId;
            const childName = this.state.selectedAudience;
            AudienceDetailsStore.LinkAudience(parentFG, parentName, childFG, childName)
                .then(() => {
                    if (AudienceDetailsStore.linkOp.success) {
                        this.reRender();
                    }
                    else {
                        this.closeAddLinkModal();
                    }
                });
        } else {
            alert("One or more required fields are empty, unable to link audiences.");
        }
    }

    @action
    private reRender = () => {
        window.location.reload();
    }

    private leftRevChanged = async (val: string) => {
        if (val) {
            const displayedVal = val;
            val = val.split(' ')[0];
            this.state.updateFetchingRevision(true);
            const response = await AudienceCriteriaService.getAudienceByRevision(this.props.match.params.functionalGroupId, this.props.match.params.audience, val);
            runInAction(() => {
                if (response.success) {
                    const aud = new Audience();
                    Object.assign(aud, response.audience);
                    this.state.updateLeftRevision(aud || new Audience(), displayedVal);
                    this.state.updateNextDiffRevisionLeft(aud.TimeStamp);
                }
                else {
                    AlertStore.add(AlertType.Error, `Failed to get criteria for audience: ${this.props.match.params.audience}, revision: ${val}`);
                    this.state.updateLeftRevision(new Audience(), "");
                }
                this.state.updateFetchingRevision(false);
            });
        } else {
            this.state.updateLeftRevision(new Audience(), "");
        }
    }

    private rightRevChanged = async (val: string) => {
        if (val) {
            const displayedVal = val;
            val = val.split(' ')[0];
            this.state.updateFetchingRevision(true);
            const response = await AudienceCriteriaService.getAudienceByRevision(this.props.match.params.functionalGroupId, this.props.match.params.audience, val);
            runInAction(() => {
                if (response.success) {
                    const aud = new Audience();
                    Object.assign(aud, response.audience);
                    this.state.updateRightRevision(aud || new Audience(), displayedVal);
                    this.state.updateNextDiffRevisionRight(aud.TimeStamp);
                }
                else {
                    AlertStore.add(AlertType.Error, `Failed to get criteria for audience: ${this.props.match.params.audience}, revision: ${val}`);
                    this.state.updateRightRevision(new Audience(), "");
                }
                this.state.updateFetchingRevision(false);
            });
        } else {
            this.state.updateRightRevision(new Audience(), "");
        }
    }

    @action
    private updateAudienceSelecIsDisabled = (boo: boolean) => {
        this.state.updateSelectedAudienceIsDisabled(boo);
    }

    @action
    private onChangeFunctionalGroup = (value: any) => {
        if (value.target.value != null) {
            this.state.updateFuncGroup(value.target.value);
            this.getFunctionalGroupAudiences();
        }
    }

    @action
    private onChangeAudienceSelected = (value: any) => {
        if (value.target.value != null) {
            this.state.updateSelectedAudience(value.target.value);
        }
    }

    private getFunctionalGroupAudiences = () => {
        this.updateAudienceSelecIsDisabled(true);
        AudienceStore.fetchFuncGroupAudience(this.state.selectedFunctionalGroupId, true).then(() => { this.updateAudienceSelecIsDisabled(false); });
    }

    @action
    private onClickEstimateWarning = () => {
        this.state.updateExpand();
    }

    @action
    private onChangeAudienceOwner = (value: any) => {
        this.state.updateAudienceOwner(value.target.value);
        this.state.updateNavigationBlocker();
    }

    @action
    private setAdmins = (value: string, op: 'add' | 'remove') => {
        const index = this.state.currentAdmins.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.state.updateAudienceAdmins([...this.state.currentAdmins, value]);
        }
        else if (op === 'remove' && index >= 0) {
            const newAdmins = this.state.currentAdmins.slice();
            newAdmins.splice(index, 1);
            this.state.updateAudienceAdmins(newAdmins);
        }

        this.state.updateNavigationBlocker();
    }

    @action
    private setLabels = (value: string, op: 'add' | 'remove') => {
        if (op === 'add' && !this.state.currentLabels.has(value)) {
            this.state.tryAddLabel(value);
        }
        else if (op === 'remove') {
            this.state.tryRemoveLabel(value);
        }

        this.state.updateNavigationBlocker();
    }

    @action
    private openAddLinkModal = () => {
        if (!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
        this.state.updateFuncGroup(localStorage.functionalGroup || this.state.selectedFunctionalGroupId);
        this.getFunctionalGroupAudiences();
        this.state.updateAddLinkModalIsOpen(true);
        setTimeout(action(() => {
            this.narratorHidden = false;
        }), 3000);
    }

    @action
    private closeAddLinkModal = () => {
        this.narratorHidden = true;
        this.state.updateAddLinkModalIsOpen(false);
    }

    @action
    private openRevisionModal = () => {
        this.state.updateRevisionModalIsOpen(true);
    }

    @action
    private closeRevisionModal = () => {
        this.state.updateRevisionModalIsOpen(false);
    }

    @action
    private startPreviewAudienceSize = () => {
        AppInsightsService.trackEvent("PreviewAudienceSizeClicked");
        const workingQuery = this.state.workingQuery;
        const audience = JSON.parse(JSON.stringify(AudienceDetailsStore.data));
        audience.Criteria = JSON.parse(workingQuery);
        EstimationStore.fetchEstimateByAudience([audience]);
        this.state.initTooltips();
        this.state.updatePreviewAudienceSizeModalIsOpen(true);
    }

    @action
    private undoChanges = () => {
        this.state.initTooltips();
        this.updateQueriesFromAudienceData(false);
    }

    @action
    private closePreviewAudienceSizeModal = () => {
        this.state.updatePreviewAudienceSizeModalIsOpen(false);
    }

    @action
    private openGroupsServiceIdModal = (value: string) => {
        this.state.updateGroupsServiceIdModalIsOpen(true);
        this.state.updateSelectedGroupsServiceId(value);
        GroupsServiceStore.fetchGroupServiceId(this.state.selectedGroupsServiceId, QueryBuilderStore.functionalGroupAppId, true, this.groupAlertStore).then(() => {
            if (GroupsServiceStore.http.success) {
                this.state.updateGroupsServiceIdDetails(GroupsServiceStore.data)
            }
        });
    }

    @action
    private closeGroupsServiceIdModal = () => {
        this.state.updateGroupsServiceIdModalIsOpen(false);
    }

    @action
    private handleRevisionOnClick = (revision: string) => {
        this.state.updateRevisionModalIsOpen(false);
        FunctionalGroupDetailsStore.fetchFunctionalGroupName(this.props.match.params.functionalGroupId, true);
        AudienceDetailsStore.fetchAudienceDetails(this.props.match.params.functionalGroupId, this.props.match.params.audience, revision, true)
            .then(() => {
                this.state.updateAudienceOwner(AudienceDetailsStore.data.Owner);
                this.state.updateAudienceAdmins(this.removeArrayDuplicates(FunctionalGroupDetailsStore.data.Owners, AudienceDetailsStore.data.Admins));
                this.state.initTooltips();
                this.updateQueriesFromAudienceData(false);
                this.updateAudienceEstimates();
                this.displayExpiryBanner();
            });
    }

    @action
    private saveChanges = () => {
        this.state.initTooltips();
        AlertStore.clearAll();
        const updates = [];
        if (this.state.isMetadataDirty) {
            updates.push(AudienceDetailsStore.updateAudienceMetadata({
                funcGroupName: AudienceDetailsStore.data.FunctionalGroup,
                audienceName: AudienceDetailsStore.data.ShortName,
                audienceOwner: this.state.audienceOwner,
                audienceAdmins: this.state.currentAdmins,
                labels: this.state.currentLabels
            }));
            this.state.setSavingState("metadata");
            updates[0].then(() => {
                if (AudienceDetailsStore.httpPatch.success) {
                    this.state.updateAudienceOwner(AudienceDetailsStore.httpPatch.data.Owner);
                    this.state.updateAudienceAdmins(this.removeArrayDuplicates(FunctionalGroupDetailsStore.data.Owners || [], AudienceDetailsStore.httpPatch.data.Admins || [], true));
                    this.state.updateOriginalOwnerAndAdmins(this.state.audienceOwner, this.state.currentAdmins);
                    this.state.updateAudienceLabels(JSON.parse(JSON.stringify(this.state.currentLabels)));
                    this.state.updateNavigationBlocker();
                } else {
                    AppInsightsService.trackEvent("FailedUpdateAudienceMetadata", {
                        "newOwner": this.state.audienceOwner,
                        "newAdmins": JSON.stringify(this.state.currentAdmins),
                        "errorMessage": AudienceDetailsStore.httpPatch.errorMessage
                    });
                }
            });
        }
        if (this.state.isCriteriaDirty) {
            const audience = JSON.parse(JSON.stringify(AudienceDetailsStore.data));
            const workingQuery = this.state.workingQuery;
            const safetyCheckQuery = this.queryBuilderComponent.current.state.audienceQueryProvider.getSerializedCriteria(this.queryBuilderComponent.current.state.tree);
            // This has happened once during testing but hasn't been reproducible since. This is a safety check to not mess up any audiences.
            if (workingQuery !== safetyCheckQuery) {
                const pendingChangesCriteria = JSON.parse(workingQuery);
                const qbCriteria = JSON.parse(safetyCheckQuery);
                this.state.setSafetyCheckUpdater((newCriteria) => {
                    audience.Criteria = newCriteria;
                    audience.Attributes = (FunctionalGroupDetailsStore.data.Properties & FunctionalGroupPropertiesMask.EditAudienceGroupInPlace || this.state.editAudInPlace)
                        ? audience.Attributes |= Attributes.EditInPlace
                        : audience.Attributes &= ~Attributes.EditInPlace;
                    audience.Owner = this.state.audienceOwner;
                    audience.Admins = this.state.currentAdmins;
                    Promise.all(updates).then(() => {
                        this.state.setSavingState("criteria");
                        updates.push(AudienceDetailsStore.updateAudienceCriteria({
                            funcGroupName: AudienceDetailsStore.data.FunctionalGroup,
                            audienceName: AudienceDetailsStore.data.ShortName,
                            audience,
                            onSuccess: (aud) => {
                                this.state.setCriteria(aud.Criteria);
                                this.state.updateWorkingQuery(JSON.stringify(aud.Criteria));
                            },
                            onFailure: () => {
                                this.state.setCriteria(audience.Criteria);
                            }
                        }));
                        updates[updates.length - 1].then(() => {
                            if (AudienceDetailsStore.httpPostCriteria.success) {
                                runInAction(() => {
                                    AudienceDetailsStore.data = AudienceDetailsStore.httpPostCriteria.data;
                                });
                                this.state.clearMissingAttributes();
                                this.state.updateEstimatesIsLoaded(false);
                                this.updateAudienceEstimates();
                            } else {
                                AppInsightsService.trackEvent("FailedUpdateAudienceCriteria", {
                                    "audience": JSON.stringify(audience),
                                    "errorMessage": AudienceDetailsStore.httpPostCriteria.errorMessage
                                });
                            }
                        });
                    });
                });

                AppInsightsService.trackEvent("UXAudienceStateCorruption", {
                    pendingChanges: pendingChangesCriteria,
                    qbCriteria
                });

                this.state.setPendingChangesCriteria(pendingChangesCriteria);
                this.state.setQbCriteria(qbCriteria);
                this.state.setCriteriaCheckIsOpen(true);
            } else {
                audience.Criteria = JSON.parse(workingQuery);
                audience.Attributes = (FunctionalGroupDetailsStore.data.Properties & FunctionalGroupPropertiesMask.EditAudienceGroupInPlace || this.state.editAudInPlace)
                    ? audience.Attributes |= Attributes.EditInPlace
                    : audience.Attributes &= ~Attributes.EditInPlace;
                audience.Owner = this.state.audienceOwner;
                audience.Admins = this.state.currentAdmins;
                Promise.all(updates).then(() => {
                    this.state.setSavingState("criteria");
                    updates.push(AudienceDetailsStore.updateAudienceCriteria({
                        funcGroupName: AudienceDetailsStore.data.FunctionalGroup,
                        audienceName: AudienceDetailsStore.data.ShortName,
                        audience,
                        onSuccess: (aud) => {
                            this.state.setCriteria(aud.Criteria);
                            this.state.updateWorkingQuery(JSON.stringify(aud.Criteria));
                        },
                        onFailure: () => {
                            this.state.setCriteria(audience.Criteria);
                        }
                    }));
                    updates[updates.length - 1].then(() => {
                        if (AudienceDetailsStore.httpPostCriteria.success) {
                            runInAction(() => {
                                AudienceDetailsStore.data = AudienceDetailsStore.httpPostCriteria.data;
                                this.state.updateNavigationBlocker();
                            });
                            this.state.clearMissingAttributes();
                            this.state.updateEstimatesIsLoaded(false);
                            this.updateAudienceEstimates();
                        } else {
                            AppInsightsService.trackEvent("FailedUpdateAudienceCriteria", {
                                "audience": JSON.stringify(audience),
                                "errorMessage": AudienceDetailsStore.httpPostCriteria.errorMessage
                            });
                        }
                    });
                });
            }
        }
    }

    private removeArrayDuplicates(array1: string[], array2: string[], caseInsensitive: boolean = false) {
        const ret = array2.filter(a => !array1.find(i => caseInsensitive ? a.toLowerCase() === i.toLowerCase() : a === i))
        return ret;
    }

    private compareSecondColumn(a: string[], b: string[]) {
        if (Number(a[1]) === Number(b[1])) {
            return 0;
        }
        else {
            return (Number(a[1]) > Number(b[1])) ? -1 : 1;
        }
    }

    private getAudienceEstimates(estimateMap: Map<string, number>) {
        const estimateArray: [string, string][] = [];
        const estimateMap2 = new Map<string, string>();
        for (const key in estimateMap) {
            if (estimateMap.hasOwnProperty(key)) {
                estimateMap2.set(key, String(estimateMap[key]));
            }
        }

        for (const pair of estimateMap2.entries()) {
            estimateArray.push(pair);
        }

        return estimateArray.sort(this.compareSecondColumn);
    }

    private getInheritedAdmins(admins: string[]) {
        if (admins.length === 0) {
            return admins;
        } else {
            let adminsString = admins[0];
            for (let i = 1; i < admins.length; i++) {
                adminsString += `, ${admins[i]}`
            }
            return adminsString;
        }
    }

    private saveDisabled() {
        return !this.state.queryBuilderFormValid || !this.state.isDirty || AudienceDetailsStore.isLoadingPatch || (AudienceDetailsStore.httpPostCriteria && AudienceDetailsStore.httpPostCriteria.inProgress) || !CriteriaStore.data
            || (this.props.match.params.revision !== null && !(isNil(this.props.match.params.revision))); // Note: Not exactly accurate but good enough (e.g. selecting the latest revision manually)
    }

    private renderMissingAttributesWarning() {
        return <div className="save-row">
            <div className={"error-text"}>
                {`WARNING: One or more attributes will be removed from this audience on save. If this is not intended, email ${config.helpAlias} for help.`}
                <ul>
                    {this.state.missingAttributes.map((v) => {
                        return <li key={`missing-attribute-${v}`}>{v}</li>;
                    })}
                </ul>
            </div>
        </div>;
    }

    private renew = () => {
        const expDate = new Date(AudienceDetailsStore.data.RevisionInfo.ExpirationDate);
        expDate.setDate(expDate.getDate() + (FunctionalGroupDetailsStore.data.AudienceExpirationSettings.DefaultExtensionPeriodDays === 0 ? 14 : FunctionalGroupDetailsStore.data.AudienceExpirationSettings.DefaultExtensionPeriodDays));
        this.state.setRenewDate(expDate);
        this.state.setExpSelectIsOpen(true);
    }

    private commitRenew = () => {
        this.state.setExpSelectIsOpen(false);
        AppInsightsService.trackEvent("RenewAudience", {
            fg: AudienceDetailsStore.data.FunctionalGroup,
            shortName: AudienceDetailsStore.data.ShortName,
            user: UserStore.username
        });

        const d = new Date(AudienceDetailsStore.data.RevisionInfo.ExpirationDate.toString());
        const extensionDays = Math.floor((this.state.renewDate.getTime() - d.getTime()) / 86400000);
        AudienceDetailsStore.extendExpiration(AudienceDetailsStore.data.FunctionalGroup, AudienceDetailsStore.data.ShortName, extensionDays)
            .then((op) => {
                if (op.success) {
                    this.displayExpiryBanner();
                    this.fetchAudience();
                }
            });
    }

    private cancelRenew = () => {
        this.state.setExpSelectIsOpen(false);
    }

    private displayExpiryBanner = () => {
        if (AudienceDetailsStore.renewExpOp && AudienceDetailsStore.renewExpOp.data) {
            const d = new Date(AudienceDetailsStore.renewExpOp.data.toString());
            AlertStore.add(AlertType.Warning, "This audience will expire on " + d.toLocaleDateString());
        } else if (AudienceDetailsStore.data.RevisionInfo && AudienceDetailsStore.data.RevisionInfo.ExpirationDate) {
            const d = new Date(AudienceDetailsStore.data.RevisionInfo.ExpirationDate.toString());
            AlertStore.add(AlertType.Warning, "This audience will expire on " + d.toLocaleDateString());
        }
    }
}

export default AppInsightsService.trackComponent(AudienceDetails);